<template>
  <div v-frag>
    <v-container class="py-10">
      <v-row align="center" justify="end" class="mx-0">
        <v-col cols="12" sm="6">
          <v-btn color="primary" @click="$router.push({ name: 'admin-projectsDetails', params: { projectId: 'uj-projekt' }})">
            {{ $t('button.addNew') }}
          </v-btn>
        </v-col>
        <v-col cols="12" sm="6" class="pa-0">
          <v-text-field
              v-model="search"
              :aria-label="$t('search')"
              :label="$t('search')"
              filled
              prepend-inner-icon="mdi-magnify"
              persistent-hint
              clearable
              :hint="$t('hint.tableSearch')"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-data-table
          :headers="headers"
          :items="projects"
          :search="search"
          :loading="loadingData"
          :loading-text="$t('loadingText.tableDataLoading')"
          :no-data-text="$t('noDataText.noEditableProjects')"
          :no-results-text="`${$t('noResultText.tableNoResult')}: ${search}`"
          sort-by="id"
      >
        <template v-slot:item.isDraft="{item}">
          {{ item.isDraft === 1 ? 'Vázlat' : 'Közzétett' }}
        </template>
        <template v-slot:item.actions="{item}">
          <v-tooltip top>
            <template v-slot:activator="{on}">
              <v-icon class="mr-3"
                      v-on="on">
                mdi-content-copy
              </v-icon>
            </template>
            <span>{{ $t('button.duplicateProject') }}</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{on}">
              <v-icon class="mr-3"
                      @click="editItem(item.id)"
                      v-on="on">
                mdi-pencil
              </v-icon>
            </template>
            <span>{{ $t('button.editProject') }}</span>
          </v-tooltip>
          <v-tooltip top v-if="item.isErasable">
            <template v-slot:activator="{on}">
              <v-icon @click="editItem(item.id)"
                      v-on="on">
                mdi-delete
              </v-icon>
            </template>
            <span>{{ $t('button.deleteProject') }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'ProjectsPage',
  components: {
    //
  },
  props: {
    //
  },
  async mounted() {
    this.projects = await this.loadTableData();
    this.loadingData = false;
  },
  data() {
    return {
      search: null,
      headers: [
        { text: 'Id', value: 'id' },
        { text: this.$t('projectName'), value: 'code' },
        { text: this.$t('status'), value: 'isDraft' },
        { text: this.$t('modifiedAt'), value: 'lastModified' },
        { text: this.$t('modifiedBy'), value: 'userName' },
        { text: '', value: 'actions' },
      ],
      projects: [],
      loadingData: true,
    };
  },
  methods: {
    async loadTableData() {
      const response = await this.$store.dispatch('GetAllProjects');
      if (response.status === 200) {
        return response.data;
      }
      return [];
    },
    editItem(id) {
      this.$router.push({ name: 'admin-projectsDetails', params: { projectId: id } });
    },
  },
};
</script>

<style scoped>

</style>
