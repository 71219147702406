var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"frag",rawName:"v-frag"}]},[_c('v-container',{staticClass:"py-10"},[_c('v-row',{staticClass:"mx-0",attrs:{"align":"center","justify":"end"}},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.$router.push({ name: 'admin-projectsDetails', params: { projectId: 'uj-projekt' }})}}},[_vm._v(" "+_vm._s(_vm.$t('button.addNew'))+" ")])],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"aria-label":_vm.$t('search'),"label":_vm.$t('search'),"filled":"","prepend-inner-icon":"mdi-magnify","persistent-hint":"","clearable":"","hint":_vm.$t('hint.tableSearch')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.projects,"search":_vm.search,"loading":_vm.loadingData,"loading-text":_vm.$t('loadingText.tableDataLoading'),"no-data-text":_vm.$t('noDataText.noEditableProjects'),"no-results-text":((_vm.$t('noResultText.tableNoResult')) + ": " + _vm.search),"sort-by":"id"},scopedSlots:_vm._u([{key:"item.isDraft",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.isDraft === 1 ? 'Vázlat' : 'Közzétett')+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-3"},on),[_vm._v(" mdi-content-copy ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('button.duplicateProject')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-3",on:{"click":function($event){return _vm.editItem(item.id)}}},on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('button.editProject')))])]),(item.isErasable)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({on:{"click":function($event){return _vm.editItem(item.id)}}},on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('button.deleteProject')))])]):_vm._e()]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }