<template>
  <div v-frag>
    <v-container>
      ProjectDetailsPage
    </v-container>
    <!-- PAGE SETTING POPUP START -->
    <v-dialog v-model="popupSettings.isVisible" scrollable persistent transition="dialog-bottom-transition" max-width="700">
      <v-card tile>
        <v-card-title class="indigo">
          <span class="white--text">
            Projekt beállításai
          </span>
        </v-card-title>
        <v-card-text class="pt-3">
          <v-form v-model="projectSettingsForm">
            <v-row>
              <v-col col="4">
                <v-radio-group v-model="protocol"
                               row
                               mandatory>
                  <v-radio value="http://" label="http://"></v-radio>
                  <v-radio value="https://" label="https://"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="8">
                <v-text-field v-model="projectSettings.host"
                              label="Projekt domain"
                              filled
                              autofocus
                              placeholder="domain.com"
                              :rules="inputRules.required"
                              hint="Kötelező kitölteni"
                              persistent-hint>
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field v-model="projectSettings.code"
                              label="Projekt címe"
                              filled
                              :rules="inputRules.required"
                              hint="Kötelező kitölteni"
                              persistent-hint>
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-select v-model="projectSettings.isDraft"
                          label="Projekt státusza"
                          :items="selectOptions.isDraft"
                          filled></v-select>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="py-4 px-5">
          <v-spacer></v-spacer>
          <v-btn text @click="popupSettings.isVisible = false" class="mr-4">
            Mégsem
          </v-btn>
          <v-btn color="primary" :disabled="!projectSettingsForm" @click="saveNewProject">
            Mentés
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- PAGE SETTING POPUP END -->
    <!-- LOADING DIALOG START -->
    <loading-and-response-dialogs :element-props.sync="loadingAndResponseDialogs"
                                  v-on:on-success-click="onSuccessClick"
                                  v-on:on-error-click="onErrorClick">
    </loading-and-response-dialogs>
    <!-- LOADING DIALOG END -->
  </div>
</template>

<script>
import LoadingAndResponseDialogs from '@/components/shared/LoadingAndResponseDialog.vue';

export default {
  name: 'ProjectDetailsPage',
  components: {
    LoadingAndResponseDialogs,
  },
  props: {
    //
  },
  async beforeMount() {
    if (this.$route.params.projectId === 'uj-projekt') {
      this.popupSettings.isVisible = true;
    } else {
      await this.getPageInEditor();
    }
  },
  data() {
    return {
      projectSettingsForm: false,
      projectSettings: {
        id: 0,
        code: '',
        contentJson: '',
        host: '',
        isDraft: 0,
      },
      popupSettings: {
        isVisible: false,
      },
      loadingAndResponseDialogs: {
        isVisible: false,
        stepper: 1,
        loadingText: 'Projekt létrehozása folyamatban.',
        successText: 'Projekt sikeresen létrehozva!',
        errorText: 'Nem sikerült létrehozni a projektet!',
        successBtnText: 'Rendben',
        errorBtnText: 'Rendben',
      },
      selectOptions: {
        isDraft: [
          { text: 'Vázlat', value: 1 },
          { text: 'Közzétett', value: 0 },
        ],
      },
      inputRules: {
        required: [
          (value) => !!value || 'Kötelező kitölteni',
        ],
      },
      protocol: 'http://',
    };
  },
  methods: {
    async saveNewProject() {
      this.projectSettings.host = `${this.protocol}${this.projectSettings.host}`;
      this.loadingAndResponseDialogs.isVisible = true;
      const response = await this.$store.dispatch('createProject', this.projectSettings);
      if (response.status === 200) {
        setTimeout(this.changeToSuccessStep, 2200);
      } else {
        setTimeout(this.changeToErrorStep, 2200);
      }
    },
    onSuccessClick() {
      this.loadingAndResponseDialogs.isVisible = false;
      this.loadingAndResponseDialogs.stepper = 1;
      this.$router.push({ name: 'admin-projects' });
    },
    onErrorClick() {
      this.loadingAndResponseDialogs.isVisible = false;
      this.loadingAndResponseDialogs.stepper = 1;
    },
    changeToSuccessStep() {
      this.loadingAndResponseDialogs.stepper = 2;
    },
    changeToErrorStep() {
      this.loadingAndResponseDialogs.stepper = 3;
    },
  },
};
</script>

<style scoped>

</style>
